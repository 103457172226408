import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { logout } from "../Authentication/AthenticationSlice";

// Fetch all payment transactions
export const fetchSupportPlansPayment = createAsyncThunk(
  "supportPlansPayment/fetchSupportPlansPayment",
  async (_, { dispatch }) => {
    try {
      const response = await api.get("/support-plans-payment", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.status === "unauthenticated") {
        dispatch(logout());
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching support plan payments:", error);
      throw error;
    }
  }
);

// Delete a payment transaction
export const deleteSupportPlansPayment = createAsyncThunk(
  "supportPlansPayment/deleteSupportPlansPayment",
  async (id, { dispatch }) => {
    try {
      await api.delete(`/support-plans-payment/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      // Fetch updated payments after deletion
      dispatch(fetchSupportPlansPayment());
    } catch (error) {
      console.error("Error deleting support plan payment:", error);
      throw error;
    }
  }
);

// Create a new payment transaction
export const createSupportPlansPayment = createAsyncThunk(
  "supportPlansPayment/createSupportPlansPayment",
  async (newPayment, { dispatch }) => {
    try {
      const response = await api.post("/support-plans-payment", newPayment, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      // Dispatch fetch updated payments after creation
      dispatch(fetchSupportPlansPayment()); // Correctly fetch updated payments

      return response.data; // Assuming response.data is the created payment object
    } catch (error) {
      console.error("Error creating support plan payment:", error);
      throw error;
    }
  }
);

// Update an existing payment transaction
export const updateSupportPlansPayment = createAsyncThunk(
  "supportPlansPayment/updateSupportPlansPayment",
  async ({ id, updatedPayment }, { dispatch }) => {
    try {
      const response = await api.put(
        `/support-plans-payment/${id}`,
        updatedPayment,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      // Fetch updated payments after update
      dispatch(fetchSupportPlansPayment());

      return response.data;
    } catch (error) {
      console.error("Error updating support plan payment:", error);
      throw error;
    }
  }
);

const supportPlansPaymentSlice = createSlice({
  name: "supportPlansPayment",
  initialState: {
    payments: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportPlansPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSupportPlansPayment.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.loading = false;
      })
      .addCase(fetchSupportPlansPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createSupportPlansPayment.fulfilled, (state, action) => {
        // Ensure the correct structure is used to add the new payment
        state.payments.push(action.payload); // Add the new payment
      })
      .addCase(deleteSupportPlansPayment.fulfilled, (state, action) => {
        // Handle deletion logic if necessary
      })
      .addCase(updateSupportPlansPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSupportPlansPayment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSupportPlansPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectSupportPlansPayment = (state) =>
  state.supportPlansPayment.payments;
export const selectLoading = (state) => state.supportPlansPayment.loading;
export const selectError = (state) => state.supportPlansPayment.error;

export default supportPlansPaymentSlice.reducer;
