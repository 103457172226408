import React from 'react';
import './FilterPopup.css';
import '../features/dashboard/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faXmark } from '@fortawesome/free-solid-svg-icons';


function FilterPopup({ isOpen, onClose, onApplyFilters, filters, handleFilterChange, filterType }) {
  if (!isOpen) return null;
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2014 + 1 }, (_, index) => currentYear - index);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFilterChange(filterType, name, value);
  };

  const clearFilters = () => {
    switch (filterType) {
      case 'totalDue':
        handleFilterChange(filterType, 'from', new Date().toISOString().split("T")[0]);
        handleFilterChange(filterType, 'to', '');
        break;
      case 'totalPaid':
        handleFilterChange(filterType, 'from', `${currentYear}-01-01`);
        handleFilterChange(filterType, 'to', `${currentYear}-12-31`);
        break;
      case 'totalSupport':
        handleFilterChange(filterType, 'year', 2024);
        break;
      case 'select-duration':
        handleFilterChange('')
      default:
        break;
    }
  };

  const renderFilterOptions = () => {
    switch (filterType) {
      case 'totalDue':
        return (
          <>
            <input
              name="from"
              value={filters?.totalDue?.from || ""}
              onChange={handleInputChange}
              type="date"
              data-type="text"
              min={new Date().toISOString().split("T")[0]}
            />
            <input
              name="to"
              value={filters?.totalDue?.to || ""}
              onChange={handleInputChange}
              type="date"
              data-type="text"
              min={filters?.totalDue?.from || ""}
            />
          </>
        );
      case 'totalPaid':
        return (
          <>
            <input
              name="from"
              value={filters.totalPaid.from || ""}
              onChange={handleInputChange}
              type="date"
              data-type="text"
            />
            <input
              name="to"
              value={filters.totalPaid.to || ""}
              onChange={handleInputChange}
              type="date"
              data-type="text"
              min={filters.totalPaid.to || ""}
            />
          </>
        );
      case 'totalSupport':
        return (
          <select
            name="year"
            className='filterSelect'
            value={filters.totalSupport.year}
            onChange={handleInputChange}
            style={{minWidth:'150px'}}
          >
            <option value="">All Time</option>

            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        );
        case 'select-duration':
          return(
            <select
            className='filterSelect'
            value={filters || ''}
            onChange={(event) => handleFilterChange(event.target.value)}
            style={{minWidth:'150px'}}
          >
            <option value="" >All Time</option>
            <option value="7">Last 7 Days</option>
            <option value="14">Last 14 Days</option>
            <option value="30">Last 30 Days</option>
          </select>
          )
      default:
        return null;
    }
  };

  return (
    <div className="filter-popup-backdrop" onClick={onClose}>
      <div className="filter-popup" onClick={(e) => e.stopPropagation()}>
        <h4 style={{ marginBottom: '1rem' }}>Filter Options</h4>
        <div className="filters" style={{ marginLeft: 0 }}>{renderFilterOptions()}</div>
        <div className="popup-buttons">
          <button className='btn btn-lg btn-primary' onClick={clearFilters} style={{ marginLeft: 'auto',borderRadius: 20, padding: "10px 20px" }}><FontAwesomeIcon
          icon={faXmark}
          style={{ paddingRight: "5px", fontSize: 12 }}
        />
        Reset</button>
    
        </div>
      </div>
    </div>
  );
}

export default FilterPopup;
