import React from "react";
import { Link } from "react-router-dom";

const SupportStates = ({ stateCounts, state, openFilterPopup }) => {
  return (
    !state && (
      <>
        <section className="support-states-container-grid-4">
          {["paid", "due", "coming Soon", "archive"].map((planState) => (
            <Link
              key={planState}
              className="support-link"
              to={`/ted-support-plans/${planState}`}
            >
              <div className="support-card">
                <h2>{stateCounts[planState] || 0}</h2>
                <h4>
                  {planState.charAt(0).toUpperCase() + planState.slice(1)} Support
                </h4>
              </div>
            </Link>
          ))}
        </section>
      </>
    )
  );
};

export default SupportStates;
