import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../utils/api";

export const sendPaymentNotice = createAsyncThunk(
  "notice/sendPaymentNotice",
  async (noticeData, { rejectWithValue }) => {
    try {
      const noticeClientData = {
        client_email: noticeData.client_email,
        client: noticeData.client,
        project: noticeData.project,
        amount: noticeData.amount,
        payment_due_date: noticeData.payment_due_date,
        notice_subject: noticeData.notice_subject,
        notice_message: noticeData.notice_message,
      };

      console.log("Sending payment notice with data:", noticeClientData);
      const response = await api.post("/notice", noticeClientData);

      if (response.status === 200) {
        console.log("Payment notice sent successfully:", response.data);
        return response.data;
      } else {
        console.error("Failed to send payment notice:", response);
        return rejectWithValue("Failed to send payment notice");
      }
    } catch (error) {
      console.error("Error sending notice:", error);
      return rejectWithValue(error.response?.data || "Error sending notice");
    }
  }
);

const noticeSlice = createSlice({
  name: "notice",
  initialState: {
    loading: false,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendPaymentNotice.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(sendPaymentNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = "Payment notice sent successfully";
        state.error = null;
      })
      .addCase(sendPaymentNotice.rejected, (state, action) => {
        state.loading = false;
        state.success = null;
        state.error = action.payload || "Failed to send notice";
      });
  },
});

// Selectors
export const selectNoticeLoading = (state) => state.notice.loading;
export const selectNoticeSuccess = (state) => state.notice.success;
export const selectNoticeError = (state) => state.notice.error;

export default noticeSlice.reducer;
