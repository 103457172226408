import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../utils/api";

// Async action to send the invoice
export const submitInvoice = createAsyncThunk(
  "invoice/submitInvoice",
  async (paymentPlanForm, { rejectWithValue }) => {
    try {
      const invoiceData = {
        id: paymentPlanForm.id,
        client_email: paymentPlanForm.client_email,
        client: paymentPlanForm.client,
        project: paymentPlanForm.project,
        amount: paymentPlanForm.amount,
        amount_paid: paymentPlanForm.amount_paid,
        payment_due_date: paymentPlanForm.payment_due_date,
        payment_date: paymentPlanForm.payment_date,
        invoice_objective: paymentPlanForm.invoice_objective,
      };

      const response = await api.post("/invoice", invoiceData);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue("Failed to send invoice email");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error sending invoice");
    }
  }
);

// Invoice slice
const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    loading: false,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(submitInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = "Invoice email sent successfully";
        state.error = null;
      })
      .addCase(submitInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = null;
        state.error = action.payload || "Failed to send invoice";
      });
  },
});

export const selectInvoiceLoading = (state) => state.invoice.loading;
export const selectInvoiceSuccess = (state) => state.invoice.success;
export const selectInvoiceError = (state) => state.invoice.error;

export default invoiceSlice.reducer;
