import "./Contacts.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchContactTypes,
  createContactType,
  fetchContactTypeById,
  updateContactType,
  deleteContactType,
} from "../contacts/ContactTypesSlice";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";

function ContactTypes() {
  const dispatch = useDispatch();
  const { contactTypes, contactType, isLoading, isSuccess, isError, message, isFormLoading } =
    useSelector((store) => store.contactTypes);

  const [ContactTypeFormOpen, setContactTypeFormOpen] = useState(false);
  const [ContactTypeForm, setContactTypeForm] = useState({
    name: "",
    color: "color1",
  });
  const [ContactTypeToEdit, setContactTypeToEdit] = useState();
  const [ContactTypeToDelete, setContactTypeToDelete] = useState();
  const [SearchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (!contactTypes) {
      dispatch(fetchContactTypes());
    }
    return () => {};
  }, [contactTypes]);

  useEffect(() => {
    if (isSuccess || isError) {
      setContactTypeFormOpen(false);
      setContactTypeToDelete(null);
      resetContactTypeForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    if (ContactTypeToEdit && !contactType) {
      dispatch(fetchContactTypeById(ContactTypeToEdit));
    } else if (ContactTypeToEdit && contactType) {
      setContactTypeForm({
        name: contactType.name,
        color: contactType.color,
      });
      setContactTypeFormOpen(true);
    }
    return () => {};
  }, [contactType, ContactTypeToEdit]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const onNewContactTypeClick = () => {
    dispatch(reset());
    setContactTypeFormOpen(true);
  };

  const onCloseModalClick = () => {
    setContactTypeToEdit(null);
    dispatch(reset());
    setContactTypeFormOpen(false);
    resetContactTypeForm();
  };

  const onMainInputChange = (field, value) => {
    setContactTypeForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetContactTypeForm = () => {
    setContactTypeForm({
      name: "",
      color: "color1",
    });
  };

  return (
    <>
      <div id="contacts">
        <div className="betterFlex">
          <Link className="no-decoration" to="/ted-contact-types">
            <h3 className="text-primary text-xl" style={{ paddingTop: 13 }}>
              Ted Contact Types
            </h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <button
            className="ml-auto btn btn-success"
            onClick={onNewContactTypeClick}
            style={{ padding: "10px 20px", borderRadius: "50px" }}
          >
            <h3>
              <FontAwesomeIcon icon={faPlus} /> New Contact Type
            </h3>
          </button>
        </div>

        <div className="d-flex gap-2">
          <input
            data-type="text"
            placeholder="Search"
            value={SearchFilter}
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
            className="bg-primary search"
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setSearchFilter("");
            }}
            style={{ padding: "10px 20px", borderRadius: "50px" }}
          >
            <h4 style={{ paddingRight: "5px" }}>
              <FontAwesomeIcon icon={faXmark} />
            </h4>
            Clear
          </button>
        </div>

        <div className="table-container">
          <div className="table-header">
            <div className="table-cell small-width" style={{ width: "7%" }}>
              Color
            </div>
            <div className="table-cell" style={{ width: "80%" }}>
              Name
            </div>
            <div className="table-cell" style={{ width: "13%" }}>
              Actions
            </div>
          </div>

          {isLoading && (
            <span
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              Loading...
            </span>
          )}
          {!isLoading &&
            contactTypes?.length > 0 &&
            contactTypes
              .filter((type) => type.name.toLowerCase().includes(SearchFilter))
              .map((type) => (
                <div className="table-row" key={type._id}>
                  <div
                    className="d-flex align-items-center table-cell small-width"
                    style={{ width: "7%" }}
                  >
                    <div
                      className={`contact-type ${type.color}`}
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "100%",
                        marginLeft: "0.8rem",
                      }}
                    ></div>
                  </div>
                  <div
                    className="d-flex align-items-center table-cell fw-semibold text-primary"
                    style={{ width: "80%" }}
                  >
                    {type.name}
                  </div>
                  <div className="d-flex">
                    <div className="table-cell ml-auto">
                      <button
                        className="btn btn-warning icon-btn"
                        onClick={() => {
                          dispatch(reset());
                          setContactTypeToEdit(type._id);
                        }}
                        style={{ borderRadius: "50px" }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>
                    <div className="table-cell">
                      <button
                        onClick={() => {
                          dispatch(reset());
                          setContactTypeToDelete(type._id);
                        }}
                        style={{ borderRadius: "50px" }}
                        className="btn icon-btn btn-danger"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

          {!isLoading && contactTypes?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Add contact type modal */}
      {ContactTypeFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (ContactTypeToEdit) {
                dispatch(
                  updateContactType({
                    id: ContactTypeToEdit,
                    data: ContactTypeForm,
                  })
                );
              } else {
                dispatch(createContactType(ContactTypeForm));
              }
            }}
          >
            <div className="contact-type-modal support-plan-modal">
              <h1 className="text-primary" style={{ marginBottom: 20 }}>
                {ContactTypeToEdit ? "Edit" : "New"} Contact Type
              </h1>
              <div className="form-group">
                <input
                  data-type="text"
                  placeholder="Name"
                  value={ContactTypeForm["name"]}
                  onChange={(event) =>
                    onMainInputChange("name", event.target.value)
                  }
                />
                <div className="d-flex color-picker gap-1">
                  <div
                    className={`contact-type color1 ${
                      ContactTypeForm["color"] === "color1" ? "active" : ""
                    }`}
                    onClick={() => onMainInputChange("color", "color1")}
                  ></div>
                  <div
                    className={`contact-type color2 ${
                      ContactTypeForm["color"] === "color2" ? "active" : ""
                    }`}
                    onClick={() => onMainInputChange("color", "color2")}
                  ></div>
                  <div
                    className={`contact-type color3 ${
                      ContactTypeForm["color"] === "color3" ? "active" : ""
                    }`}
                    onClick={() => onMainInputChange("color", "color3")}
                  ></div>
                  <div
                    className={`contact-type color4 ${
                      ContactTypeForm["color"] === "color4" ? "active" : ""
                    }`}
                    onClick={() => onMainInputChange("color", "color4")}
                  ></div>
                  <div
                    className={`contact-type color5 ${
                      ContactTypeForm["color"] === "color5" ? "active" : ""
                    }`}
                    onClick={() => onMainInputChange("color", "color5")}
                  ></div>
                  <div
                    className={`contact-type color6 ${
                      ContactTypeForm["color"] === "color6" ? "active" : ""
                    }`}
                    onClick={() => onMainInputChange("color", "color6")}
                  ></div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                disabled={isFormLoading}
                className="btn btn-lg btn-success mx-auto"
                type="submit"
              >
                <h4>{isFormLoading ? "Loading..." : "Save"}</h4>
              </button>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete Contact Type Modal */}
      {ContactTypeToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setContactTypeToDelete(null);
          }}
        >
          <div className="delete-contact-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this contact type?
            </h3>
            <button
              onClick={() => {
                dispatch(deleteContactType(ContactTypeToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ContactTypes;
