import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

export const showToast = (message, type = "success") => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: { backgroundColor: "#1683FF", color: "#F0F6FF", fontWeight: "bold" },
    progressStyle: { background: "#F0F6FF" },
    icon: <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#F0F6FF" }} />,
    closeButton: (
      <FontAwesomeIcon
        icon={faTimes}
        style={{ color: "#F0F6FF", cursor: "pointer" }}
      />
    ),
  });
};


// add toast danger for errors