import "./Modal.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function Modal({ children, onCloseModalClick, classes }) {
  return (
    <div className="modal" onClick={onCloseModalClick}>
      <div
        className={classes}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <button onClick={onCloseModalClick} className="close-modal">
          <FontAwesomeIcon icon={faXmark} />
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
