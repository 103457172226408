import '../dashboard/Dashboard.css'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMainNumbers } from './ProjectsSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
function ProjectDashboard() {
  const currentYear = new Date().getFullYear();


  

  const dispatch = useDispatch();
  const { mainNumbers } = useSelector((store) => store.dashboard);
  const [filters, setFilters] = useState({
    totalPaid: { 
      from: `${currentYear}-01-01`, 
      to: `${currentYear}-12-31` 
    },
    totalDue: { from: new Date().toISOString().split("T")[0], to: "" },
    totalSupport: { year: currentYear },
  });
  useEffect(() => {
    console.log('useEffect')
    dispatch(fetchMainNumbers(filters));
  }, [dispatch,filters]);

  const handleFilterChange = (type, field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: {
        ...prevFilters[type],
        [field]: value,
      },
    }));
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h3 style={{ paddingTop: 3 }}>Ted Projects</h3>
        <Link
            style={{ textDecoration: "none" }}
            className="ml-auto btn btn-primary pproject transitionUp"
            to="/"
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ paddingRight: "5px" }}
            />
            Back to Dashboard
          </Link>
      </header>
      {/* <h5>Projects</h5> */}
     
    
      <section className="project-states-container">
        {["leads", "meetings", "proposals", "sales", "approved"].map(
          (projectType) => (
            <Link
              key={projectType}
              className="project-link"
              to={`/ted-projects/${projectType}`}
            >
              <div className="project-card">
                <h2>
                  {mainNumbers?.project_counts?.[projectType]?.[0]?.count || 0}
                </h2>
                <h4>
                  {projectType.charAt(0).toUpperCase() + projectType.slice(1)}
                </h4>

              </div>

            </Link>
          )
        )}
      </section>
      {/* <h5>Projects and Contacts</h5> */}
      <section className="summary-container">
        <div className="summary-box">
          <Link to={"/ted-projects/rejected"} className="summary-link">
            <h2>{mainNumbers?.project_counts?.rejected?.[0]?.count || 0}</h2>
            <h4>Rejected</h4>
          </Link>
        </div>
        <div className="summary-box">
          <Link to={"/ted-projects/stopped"} className="summary-link">
            <h2>{mainNumbers?.project_counts?.stopped?.[0]?.count || 0}</h2>
            <h4>Stopped</h4>
          </Link>
        </div>
        <div className="summary-box">
          <Link to={"/ted-contacts"} className="summary-link">
            <h2>{mainNumbers?.contacts_count || 0}</h2>
            <h4>Contacts</h4>
          </Link>
        </div>
        <div className="summary-box">
          <Link to={"/ted-teasing-contacts"} className="summary-link">
            <h2>{mainNumbers?.teasing_contacts_count || 0}</h2>
            <h4>Teasing Contacts</h4>
          </Link>
        </div>
      </section>

    </div>
  );
}

export default ProjectDashboard;
