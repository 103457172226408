import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const SearchBar = ({
  searchFilter,
  setSearchFilter,
  selectedDuration,
  setSelectedDuration,
  state,
  setSelectedStatus,
}) => {
  return (
    <div className="d-flex gap-2 w-100 align-items-center m-6">
      <input
        type="text"
        placeholder="Search"
        value={searchFilter}
        onChange={(event) => setSearchFilter(event.target.value)}
        className="bg-primary search"
      />
          {/* <button
        className="btn  btn-primary"
        onClick={() => {
          setSearchFilter("");
        }}
        style={{ borderRadius: 50}}
      >
        <FontAwesomeIcon
          icon={faXmark}
        />
      </button> */}
      {/* {(state =='due' ||state==null) &&(
        <select
        
          value={selectedDuration}
          onChange={(event) => setSelectedDuration(event.target.value)}
          className="filterSelect"
        >
          <option value="" >All Time</option>
          <option value="7">Last 7 Days</option>
          <option value="14">Last 14 Days</option>
          <option value="30">Last 30 Days</option>
        </select>
      )}
   */}
    </div>
  );
};

export default SearchBar;
