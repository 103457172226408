import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupportPlans,
  createSupportPlan,
  updateSupportPlan,
  deleteSupportPlan,
  selectSupportPlans,
  selectError,
} from "./SupportPlansSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faExchange,
  faXmark,
  faCheck,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FilterPopup from "../../components/FilterPopup";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./SupportPlans.css";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  createSupportPlansPayment,
  fetchSupportPlansPayment,
  selectSupportPlansPayment,
} from "./SupportPlanPaymentSlice";
import SupportStates from "./components/SupportStates";
import SearchBar from "./components/SearchBar";
import SupportContextMenu from "./components/SupportContextMenu";
import CreatePlanModal from "./components/CreatePlanModal";
import SendNoticeModal from "./components/Notice/SendNoticeModal";
const SupportPlans = () => {
  const { state } = useParams();
  const dispatch = useDispatch();
  const plans = useSelector(selectSupportPlans);
  const error = useSelector(selectError);
  const [searchFilter, setSearchFilter] = useState("");
  const [SupportMenuOpen, setSupportMenuOpen] = useState(false);
  const [SupportMenuPosition, setSupportMenuPosition] = useState([0, 0]);
  const [contextSupport, setContextSupport] = useState(null);
  const [createPlan, setCreatePlan] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [planToEdit, setPlanToEdit] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [sendNotice, setSendNotice] = useState(false);
  const [hiddenSubmissionMessage, setHiddenSubmissionMessage] = useState(true);
  const [placeHolder, setPlaceHolder] = useState("");
  const [order, setOrder] = useState("asc");
  const [dateType, setdateType] = useState("text");
  const [selectedOptions, setSelectedOptions] = useState(["Status"]);
  const [selectedMonth, setSelectedMonth] = useState("");
  /*
  const options = [
    "Start Date",
    "End Date",
    "Client",
    "Amount",
    "To Be Collected",
    "Status",
  ];
  */
  const [planForm, setPlanForm] = useState({
    client: "",
    project: "",
    client_email: "",
    amount: "",
    status: "",
    note: "",
    payment_due_date: "",
    start_project_date: "",
    end_project_date: "",
  });

  const handleMonthChange = (event) => {
    setEndDate(null);
    setStartDate(null);
    setStart({ date: null, text: "January" });
    setEnd({ date: null, text: "Current" });
    setSelectedMonth(event.target.value);
  };
  const [popupFilter, setPopupFilter] = useState(null);
  const [dueFilter, setDueFilter] = useState("");
  const openFilterPopup = () => {
    setPopupFilter("totalDue");
  };
  const closeFilterPopup = () => {
    setPopupFilter(null);
  };
  const applyFilters = () => {
    closeFilterPopup();
  };
  const handleFilterChange = (value) => {
    setDueFilter(value);
    closeFilterPopup();
  };
  /*
  const handleDateType = () => {
    setSelectedMonth("");
    if (dateType == "text") {
      setdateType("date");
      setStartDate(null);
      setEndDate(null);
    } else {
      setdateType("text");
      setStartDate(null);
      setEndDate(null);
      setStart({ date: getCurrentYearStart(), text: "January" });
      setEnd({ date: getCurrentDate(), text: "Current" });
    }
  };
  */
  const handleCreatePlanClick = () => {
    setPlanToEdit(null);
    setPlanForm({
      client: "",
      client_email: "",
      amount: "",
      status: "",
      note: "",
      start_project_date: "",
      end_project_date: "",
    });
    setCreatePlan(true);
  };

  const validateForm = () => {
    let errors = {};

    if (!planForm.client) errors.client = "Client is required.";

    if (!planForm.client_email) errors.client_email = "Email is required.";
    if (!planForm.amount) errors.amount = "Amount is required.";
    if (!planForm.status) errors.status = "Status is required.";
    if (!planForm.start_project_date)
      errors.start_project_date = "Start date is required.";
    if (!planForm.end_project_date)
      errors.end_project_date = "End date is required.";

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const getCurrentYearStart = () => {
    const currentYear = new Date().getFullYear();
    return new Date(Date.UTC(currentYear, 0, 1)).toISOString();
  };

  const getCurrentDate = () => {
    return new Date().toISOString();
  };

  const getEndOfYear = () => {
    return new Date(Date.UTC(new Date().getFullYear(), 11, 31)).toISOString();
  };

  const [start, setStart] = useState({
    date: getCurrentYearStart(),
    text: "January",
  });

  const [end, setEnd] = useState({
    date: getCurrentDate(),
    text: "Current",
  });
  useEffect(() => {
    if (state) {
      setStart({ date: null, text: "January" });
      setEnd({ date: null, text: "Current" });
      setSelectedMonth("");
      setdateType("date");
      if (state === "due") {
        setSelectedOptions(["End Date"]);
        setOrder("desc");
      } else {
        setSelectedOptions([]);
        setSelectedDuration("");
      }
    } else {
      setSelectedMonth("");
      setdateType("text");
      setOrder("asc");
      setSelectedOptions(["Status"]);
      setStartDate(null);
      setEndDate(null);
      setStart({ date: getCurrentYearStart(), text: "January" });
      setEnd({ date: getCurrentDate(), text: "Current" });
    }
  }, [state]);

  const handleSwitchDates = () => {
    if (start.text === "January") {
      setStart({ date: getCurrentDate(), text: "Current" });
      setEnd({ date: getEndOfYear(), text: "December" });
    } else {
      setStart({ date: getCurrentYearStart(), text: "January" });
      setEnd({ date: getCurrentDate(), text: "Current" });
    }
  };

  const handleEditPlanClick = (plan) => {
    setPlanToEdit(plan._id);
    setPlanForm({
      client: plan.client,
      project: plan.project,
      client_email: plan.client_email,
      amount: plan.amount,
      status: plan.status,
      note: plan.note,
      payment_due_date: formFormatDate(plan.payment_due_date),
      start_project_date: formFormatDate(plan.start_project_date),
      end_project_date: formFormatDate(plan.end_project_date),
    });
    setCreatePlan(true);
  };

  const handleSendNotice = (plan) => {
    setPlanForm({
      client: plan.client,
      project: plan.project,
      client_email: plan.client_email,
      amount: plan.amount,
      payment_due_date: formFormatDate(plan.payment_due_date),
      start_project_date: formFormatDate(plan.start_project_date),
      end_project_date: formFormatDate(plan.end_project_date),
    });
    setSendNotice(true);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const startDate = new Date(planForm.start_project_date);
    const endDate = new Date(planForm.end_project_date);

    if (startDate > endDate) {
      setFormErrors({
        ...formErrors,
        end_project_date: "End date must be later than the start date",
      });
      return;
    }

    if (planToEdit) {
      dispatch(updateSupportPlan({ id: planToEdit, updatedPlan: planForm }));
    } else {
      dispatch(createSupportPlan(planForm));
    }

    setCreatePlan(false);
  };

  useEffect(() => {
    dispatch(fetchSupportPlans());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSupportPlansPayment());
  }, [dispatch]);

  const supportPlansPayment = useSelector(selectSupportPlansPayment);

  const handleDeletePlan = (id) => {
    dispatch(deleteSupportPlan(id));
    setSupportMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setContextSupport(null);
      setSupportMenuOpen(false);
    });
    document.addEventListener("contextmenu", () => {
      setContextSupport(null);
      setSupportMenuOpen(false);
    });
  }, []);

  /*
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    plans.forEach((plan) => {
      const endDate = new Date(plan.end_project_date);
      if (
        endDate < currentDate &&
        plan.status !== "archive" &&
        plan.status !== "new"
      ) {
        dispatch(
          updateSupportPlan({
            id: plan._id,
            updatedPlan: { status: "archive" },
          })
        );
      }
    });
  }, [plans, dispatch]);
  */

  const filteredPlans = plans.filter((plan) => {
    const matchesSearch =
      (plan.client?.toLowerCase() || "").includes(searchFilter.toLowerCase()) ||
      (plan.project?.toLowerCase() || "").includes(
        searchFilter.toLowerCase()
      ) ||
      (plan.client_email?.toLowerCase() || "").includes(
        searchFilter.toLowerCase()
      );

    const durationDays = parseInt(selectedDuration);
    const dueDateThreshold = durationDays;

    const matchesStatus =
      selectedDuration !== ""
        ? plan.status.toLowerCase() === "active" &&
          new Date(plan.end_project_date).getTime() <=
            new Date(Date.now() + durationDays * 24 * 60 * 60 * 1000).getTime()
        : true;
    return matchesSearch;
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDateChange = (e) => {
    setSelectedMonth("");
    const { name, value } = e.target;
    if (name === "from") {
      setStartDate(value);

      setStart((prev) => ({
        ...prev,
        date: null,
      }));

      setEnd((prev) => ({
        ...prev,
        date: null,
      }));
    } else if (name === "to") {
      setStart((prev) => ({
        ...prev,
        date: null,
      }));

      setEnd((prev) => ({
        ...prev,
        date: null,
      }));
      setEndDate(value);
    }
  };
  if (error) return <p>Error: {error}</p>;

  const sortedPlans =
    selectedDuration !== ""
      ? filteredPlans
          .filter((plan) => plan.status.toLowerCase() === "active")
          .sort(
            (a, b) =>
              new Date(a.start_project_date) - new Date(b.start_project_date)
          )
      : filteredPlans.sort(
          (b, a) =>
            new Date(a.start_project_date) - new Date(b.start_project_date)
        );

  const formFormatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "2-digit" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
  };

  const stateCounts = plans.reduce((acc, plan) => {
    const status = plan.status.toLowerCase();
    acc[status] = (acc[status] || 0) + 1;

    if (plan.dueAmount > 0 && plan.status.toLowerCase() === "active") {
      const endDate = new Date(plan.start_project_date);
      const today = new Date();

      endDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      const endDateStr = endDate.toISOString().split("T")[0];
      const todayStr = today.toISOString().split("T")[0];

      if (dueFilter !== "") {
        const dueDays = parseInt(dueFilter);
        const dueThresholdDate = new Date();
        dueThresholdDate.setDate(today.getDate() + dueDays);
        dueThresholdDate.setHours(0, 0, 0, 0);

        const dueThresholdDateStr = dueThresholdDate
          .toISOString()
          .split("T")[0];

        if (endDateStr >= todayStr && endDateStr <= dueThresholdDateStr) {
          acc["due"] = (acc["due"] || 0) + 1;
        }
      } else {
        acc["due"] = (acc["due"] || 0) + 1;
      }
    } else if (plan.dueAmount === 0 && plan.status.toLowerCase() === "active") {
      acc["paid"] = (acc["paid"] || 0) + 1;
    }

    acc["coming Soon"] = acc["new"];
    return acc;
  }, {});

  const handlePaymentAmountSubmit = (planId, e) => {
    e.stopPropagation();

    const plan = filteredPlans.find((plan) => plan._id === planId);
    if (!plan) {
      console.log("No plan found with this ID.");
      return;
    }

    const isFullyPaid = supportPlansPayment.some((payment) => {
      const supportPlanId =
        typeof payment.support_plan === "string"
          ? payment.support_plan
          : payment.support_plan?._id;
      return supportPlanId === planId && payment.amount_paid >= plan.amount;
    });

    if (isFullyPaid) {
      showMessage("Fully Paid.");
      return;
    }

    // Dispatch the payment for the full amount
    dispatch(
      createSupportPlansPayment({
        support_plan: planId,
        amount_paid: plan.amount,
      })
    );

    showMessage(`Payment completed.`);
    setSupportMenuOpen(false);
    dispatch(fetchSupportPlans());
  };

  const showMessage = (message) => {
    setSubmissionMessage(message);

    setTimeout(() => {
      setHiddenSubmissionMessage(false);
    }, 100);

    setTimeout(() => {
      setHiddenSubmissionMessage(true);
      setSubmissionMessage("");
    }, 5000);
  };

  const checkIfInputShouldBeDisabled = (totalPaid, planAmount) => {
    if (totalPaid >= planAmount) {
      setIsInputDisabled(true);
      console.log("Input disabled: the plan is fully paid.");
    } else {
      setIsInputDisabled(false);
      console.log("Input enabled: the plan is not fully paid.");
    }
  };

  const handlePaymentAmountChange = (planId) => (e) => {
    const value = e.target.value;

    if (/^[0-9]*$/.test(value)) {
      const plan = filteredPlans.find((plan) => plan._id === planId);

      const totalPaidSoFar = supportPlansPayment
        .filter((payment) => {
          const supportPlanId =
            typeof payment.support_plan === "string"
              ? payment.support_plan
              : payment.support_plan?._id;
          return supportPlanId === planId;
        })
        .reduce((acc, payment) => acc + payment.amount_paid, 0);

      const remainingAmount = plan.amount - totalPaidSoFar;

      if (value <= remainingAmount) {
        setAmountPaid(value);
      }
    }
  };

  const columns = [
    {
      field: "start_project_date",
      headerName: "Start Date",
      flex: 0.8,
      renderCell: (params) => {
        return formatDate(params.value);
      },
      sortComparator: (v1, v2) => {
        return new Date(v1) - new Date(v2);
      },
    },
    {
      field: "end_project_date",
      headerName: "End Date",
      flex: 0.8,
      renderCell: (params) => {
        return formatDate(params.value);
      },
      sortComparator: (v1, v2) => {
        return new Date(v1) - new Date(v2);
      },
    },
    { field: "client", headerName: "Client", flex: 2 },

    {
      field: "amount",
      headerName: "Amount",
      flex: 0.8,
      renderCell: (params) => {
        const amount = parseFloat(
          params.value.replace("$", "").replace(",", "")
        );
        return `$${amount.toLocaleString()}`;
      },
      sortComparator: (v1, v2) => {
        const amount1 = parseFloat(v1.replace("$", "").replace(",", ""));
        const amount2 = parseFloat(v2.replace("$", "").replace(",", ""));
        return amount1 - amount2;
      },
    },
    {
      field: "dueAmount",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value === "X" ? (
          <FontAwesomeIcon
            icon={faCheck}
            size="xl"
            style={{ color: "green", opacity: 0.8 }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faXmark}
            size="xl"
            style={{ color: "red", opacity: 0.8 }}
          />
        ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.2,
      renderCell: (params) => {
        const handleOpenContextMenu = (event) => {
          event.preventDefault();
          event.stopPropagation();

          let x = event.clientX;
          let y = event.clientY;

          if (x + 240 > window.innerWidth) {
            x -= 240;
          }
          if (y + 200 > window.innerHeight) {
            y -= 250;
          }

          setSupportMenuPosition([x, y]);
          setContextSupport(params.id);
          setSubmissionMessage("");

          // Find the relevant plan based on the selected context
          const plan = filteredPlans.find((plan) => plan._id === params.id);

          if (plan) {
            const filteredPayments = supportPlansPayment.filter((payment) => {
              const supportPlanId =
                typeof payment.support_plan === "string"
                  ? payment.support_plan
                  : payment.support_plan?._id;

              return supportPlanId === plan._id;
            });

            const totalPaidSoFar = filteredPayments.reduce((acc, payment) => {
              return acc + payment.amount_paid;
            }, 0);

            checkIfInputShouldBeDisabled(totalPaidSoFar, plan.amount);

            const remainingAmount = plan.amount - totalPaidSoFar;
            if (remainingAmount === 0) {
              setPlaceHolder("Fully Paid");
            } else {
              setPlaceHolder(`$${remainingAmount}`);
            }
          } else {
            console.log("No plan found with this ID.");
          }

          setTimeout(() => {
            setSupportMenuOpen(true);
          }, 100);
        };

        return (
          <IconButton onClick={handleOpenContextMenu}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
  ];
  const reset = () => {
    if (state) {
      setOrder("asc");
      setSelectedOptions([]);
      if (state === "due") {
        setSelectedOptions(["End Date"]);
      }
      setdateType("date");
      setStartDate(null);
      setEndDate(null);
      setStart({ date: getCurrentYearStart(), text: "January" });
      setEnd({ date: getCurrentDate(), text: "Current" });
      setSelectedMonth("");
    } else {
      setOrder("asc");
      setSelectedOptions(["Status"]);
      setdateType("text");
      setStartDate(null);
      setEndDate(null);
      setStart({ date: getCurrentYearStart(), text: "January" });
      setEnd({ date: getCurrentDate(), text: "Current" });
      setSelectedMonth("");
    }
  };

  const rows = sortedPlans
    .map((plan) => {
      const startDate = plan.start_project_date;
      const endDate = plan.end_project_date;

      let duration;
      if (startDate && endDate) {
        duration = `${new Date(startDate).toLocaleDateString()} - ${new Date(
          endDate
        ).toLocaleDateString()}`;
      } else if (startDate) {
        duration = new Date(startDate).toLocaleDateString();
      } else if (endDate) {
        duration = new Date(endDate).toLocaleDateString();
      } else {
        duration = "";
      }

      return {
        id: plan._id,
        client: plan.client,
        project: plan.project,
        client_email: plan.client_email,
        amount: `$${plan.amount}`,
        status: plan.status,
        note: plan.note || "-",
        payment_due_date: plan.payment_due_date,
        start_project_date: startDate,
        end_project_date: endDate,
        dueAmount: plan.dueAmount === 0 ? "X" : ` `,
      };
    })
    .filter((plan) => {
      const planEndDate = new Date(plan.start_project_date)
        .toISOString()
        .split("T")[0];

      let status;
      if (state == "coming Soon") {
        status = "new";
      } else if (state == "due") {
        status = null;
      } else if (!state) {
        status = "active";
      } else {
        status = state;
      }

      let stateFilter = !status || plan.status === status;
      if (state === "due") {
        stateFilter = plan.dueAmount !== "X" && plan.status === "active";
      }
      if (state == "paid") {
        stateFilter = plan.dueAmount === "X" && plan.status === "active";
      }

      const startFilter =
        !start.date ||
        planEndDate >= new Date(start.date).toISOString().split("T")[0];
      const endFilter =
        !end.date ||
        planEndDate <= new Date(end.date).toISOString().split("T")[0];

      if (startDate || endDate) {
        const startDateStr = startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : null;
        const endDateStr = endDate
          ? new Date(endDate).toISOString().split("T")[0]
          : null;

        const startFilter2 = !startDateStr || planEndDate >= startDateStr;
        const endFilter2 = !endDateStr || planEndDate <= endDateStr;

        return startFilter2 && endFilter2 && stateFilter;
      }

      const monthFilter = () => {
        if (selectedMonth == "") return true;
        const planEndMonth = new Date(plan.start_project_date).getMonth() + 1;
        return planEndMonth == selectedMonth;
      };

      return (
        startFilter && endFilter && stateFilter && monthFilter(selectedMonth)
      );
    })

    .sort((a, b) => {
      /*
      const order_status = {
        active: 1,
        new: 2,
        archive: 3,
        inactive: 4,
      };

      const sortMultiplier = order === "asc" ? 1 : -1;

      const dueAmountA =
        a.dueAmount === "X" ? 0 : parseFloat(a.dueAmount.replace("$", ""));
      const dueAmountB =
        b.dueAmount === "X" ? 0 : parseFloat(b.dueAmount.replace("$", ""));

      let sortValue = 0;

      */

      // for (const option of selectedOptions) {
      //   switch (option) {
      //     case "Start Date":
      //       const startDateA = new Date(a.start_project_date);
      //       const startDateB = new Date(b.start_project_date);
      //       sortValue = (startDateA - startDateB) * sortMultiplier;
      //       break;

      //     case "End Date":
      //       const endDateA = new Date(a.end_project_date);
      //       const endDateB = new Date(b.end_project_date);
      //       sortValue = (endDateA - endDateB) * sortMultiplier;
      //       break;

      //     case "Client":
      //       sortValue = a.client.localeCompare(b.client) * sortMultiplier;
      //       break;

      //     case "Amount":
      //       sortValue = (dueAmountA - dueAmountB) * sortMultiplier;
      //       break;

      //     case "To Be Collected":
      //       sortValue = (dueAmountA - dueAmountB) * sortMultiplier;
      //       break;

      //     case "Status":
      //       sortValue = ((order_status[a.status] || 5) - (order_status[b.status] || 5)) * sortMultiplier;
      //       break;

      //     default:
      //       break;
      //   }

      //   if (sortValue !== 0) {
      //     return sortValue;
      //   }
      // }

      return 0;
    });

  return (
    <div id="support-plans">
      <div
        className="d-flex gap-2 align-items-center m-6"
        style={{ justifyContent: "space-between" }}
      >
        <Link className="no-decoration" to="/">
          <h3 className="text-primary text-xl" style={{ paddingTop: 13 }}>
            Ted {state ? state.charAt(0).toUpperCase() + state.slice(1) : ""}{" "}
            Support
          </h3>
        </Link>
        <div className="d-flex align-items-center" style={{ gap: 10 }}>
          {state && (
            <Link
              style={{ textDecoration: "none" }}
              className="ml-auto btn btn-primary pproject transitionUp"
              to="/ted-support-plans"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ paddingRight: "5px" }}
              />
              Back to Support
            </Link>
          )}
          {!state && (
            <Link
              style={{ textDecoration: "none" }}
              className="ml-auto btn btn-primary pproject transitionUp"
              to="/"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ paddingRight: "5px" }}
              />
              Back to Dashboard
            </Link>
          )}
          {/* <button
            className="ml-auto btn transitionUp purple-button"
            onClick={() => console.log("Import Excel clicked")}
          >
            <FontAwesomeIcon icon={faFileImport} style={{ paddingRight: "10px" }} />
            Import Excel
          </button> */}
          <button
            className="ml-auto btn btn-success transitionUp"
            onAbort={() => setCreatePlan(true)}
            onClick={() => handleCreatePlanClick()}
            style={{
              fontSize: 15,
              fontWeight: "bold",
              padding: "11px 20px",
              borderRadius: 20,
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
            New Support
          </button>
        </div>
      </div>

      <SupportStates
        stateCounts={stateCounts}
        state={state}
        openFilterPopup={openFilterPopup}
      />

      {/* Search Bar */}
      <SearchBar
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        state={state}
        setSelectedStatus={setSelectedStatus}
      />
      {/* filters */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1.5em",
          minWidth: "fit-content",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            width: "100%",
          }}
        >
          {
            <>
              <input
                data-type="text"
                type="text"
                value={startDate != null || endDate != null ? "" : start.text}
                onChange={handleDateChange}
                readOnly
                disabled={
                  endDate != null || startDate != null || selectedMonth !== ""
                }
                style={{
                  width: "100%",
                  height: "37px",
                  borderRadius: "10px",
                  border: "none",
                }}
              />
              <button
                onClick={handleSwitchDates}
                disabled={
                  endDate != null || startDate != null || selectedMonth !== ""
                }
                className="btn btn-primary"
                style={{ marginLeft: -10, marginRight: -10 }}
              >
                <FontAwesomeIcon icon={faExchange} />
              </button>
              <input
                data-type="text"
                type="text"
                value={(endDate != null) | (startDate != null) ? "" : end.text}
                disabled={
                  endDate != null || startDate != null || selectedMonth !== ""
                }
                onChange={handleDateChange}
                readOnly
                style={{
                  width: "100%",
                  height: "37px",
                  borderRadius: "10px",
                  border: "none",
                }}
              />
              <div
                style={{
                  width: 10,
                  height: 20,
                  borderRightWidth: 2,
                  borderRightColor: "lightgray",
                  borderRightStyle: "solid",
                }}
              ></div>
              <select
                id="month"
                className="filterSelect"
                value={selectedMonth}
                onChange={handleMonthChange}
                style={{ width: "100%" }}
              >
                <option value="">All Months</option>
                {Array.from({ length: 12 }, (_, index) => {
                  const month = new Date(0, index).toLocaleString("default", {
                    month: "long",
                  });
                  return (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  );
                })}
              </select>

              <div
                style={{
                  width: 10,
                  height: 20,
                  borderRightWidth: 2,
                  borderRightColor: "lightgray",
                  borderRightStyle: "solid",
                }}
              ></div>

              <input
                name="from"
                value={startDate || ""}
                disabled={selectedMonth !== ""}
                data-type="text"
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setSelectedMonth("");
                }}
                style={{
                  width: "100%",
                  height: "37px",
                  borderRadius: "10px",
                  border: "none",
                }}
              />
              <input
                name="to"
                value={endDate || ""}
                disabled={selectedMonth !== ""}
                data-type="text"
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setSelectedMonth("");
                }}
                style={{
                  width: "100%",
                  height: "37px",
                  borderRadius: "10px",
                  border: "none",
                }}
              />
              <div style={{ display: "flex", gap: "1rem", flexGrow: 1 }}>
                <button
                  className="btn btn-lg btn-primary"
                  onClick={reset}
                  style={{
                    borderRadius: 20,
                    padding: "10px",
                    marginLeft: "auto",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ fontSize: 16, width: 16, height: 16 }}
                  />
                </button>
              </div>
            </>
          }
        </div>
      </div>

      {/* Data Grid */}
      <div className="table-container">
        <div className="plans-table">
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter
            rowHeight={50}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "all" : "even-row"
            }
            sx={{
              borderRadius: 2,
              border: "none",
              "& .even-row": {
                backgroundColor:
                  state === "coming Soon"
                    ? "#FFC9C9"
                    : state === "archive"
                    ? "#fff2b8"
                    : "#f0f4ff",
              },
              "& .all": {
                backgroundColor:
                  state === "coming Soon"
                    ? "#FFC9C970"
                    : state === "archive"
                    ? "#fff2b870"
                    : "#fff",
              },
            }}
            components={{
              NoRowsOverlay: () => (
                <div
                  style={{
                    display: "flex",
                    height: "20%",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                  }}
                >
                  No supports available.
                </div>
              ),
            }}
          />
        </div>
      </div>

      {/* Context Menu */}
      <SupportContextMenu
        SupportMenuOpen={SupportMenuOpen}
        SupportMenuPosition={SupportMenuPosition}
        contextSupport={contextSupport}
        filteredPlans={filteredPlans}
        state={state}
        submissionMessage={submissionMessage}
        hiddenSubmissionMessage={hiddenSubmissionMessage}
        amountPaid={amountPaid}
        handlePaymentAmountChange={handlePaymentAmountChange}
        handlePaymentAmountSubmit={handlePaymentAmountSubmit}
        isInputDisabled={isInputDisabled}
        handleEditPlanClick={handleEditPlanClick}
        handleSendNotice={handleSendNotice}
        dispatch={dispatch}
        updateSupportPlan={updateSupportPlan}
        handleDeletePlan={handleDeletePlan}
        setSupportMenuOpen={setSupportMenuOpen}
        placeHolder={placeHolder}
      />

      {/* Create Plan Modal */}
      <CreatePlanModal
        createPlan={createPlan}
        setCreatePlan={setCreatePlan}
        handleFormSubmit={handleFormSubmit}
        planForm={planForm}
        setPlanForm={setPlanForm}
        formErrors={formErrors}
        planToEdit={planToEdit}
      />

      {/* Send Notice Modal*/}

      <SendNoticeModal
        sendNotice={sendNotice}
        setSendNotice={setSendNotice}
        planForm={planForm}
        formErrors={formErrors}
      />
      <FilterPopup
        isOpen={popupFilter !== null}
        onClose={closeFilterPopup}
        onApplyFilters={applyFilters}
        filters={dueFilter}
        handleFilterChange={handleFilterChange}
        filterType={"select-duration"}
      />
    </div>
  );
};

export default SupportPlans;
