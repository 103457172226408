import "./Sidebar.css";
import { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faAddressBook,
  faUserGear,
  faUserGroup,
  faUserTag,
  faUserTie,
  faFolder,
  faFileSignature,
  faPhone,
  faDollarSign,
  faThumbsUp,
  faBan,
  faHand,
  faChevronRight,
  faBullhorn,
  faXmark,
  faTableList,
  faListOl,
  faSackDollar,
  faChartSimple,
  faCircleInfo,
  faFolderOpen,
  faFileInvoice,
  faCheck,
  faCheckCircle,
  faClock,
  faBoxArchive,
  faAddressCard,
  faTableCo,
  faGrip,
  faStar,
  faXmarkCircle,
  faTableCellsLarge,
  faSquarePollVertical,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./tedmob-logo.png";

function Sidebar({
  sidebarOpen,
  sidebarExpanded,
  sidebarRight,
  toggleSidebarExpanded,
  toggleSidebar,
  closeSidebar,
}) {
  const { authUser } = useSelector((store) => store.authentication);

  const sidebar_content = [
    {
      type: "link",
      label: "Dashboard",
      route: "/",
      icon: faSquarePollVertical,
    },
    {
      type: "section",
      title: "Users",
      link: "/ted-users",
      icon: faUserTie,
      inside: [
        {
          type: "link",
          label: "All Users",
          route: "/ted-users",
          permission: "users",
          icon: faUserGroup,
        },
        {
          type: "link",
          label: "Roles",
          route: "/ted-roles",
          permission: "roles",
          icon: faUserTag,
        },
      ],
    },
    {
      type: "section",
      title: "Contacts",
      link: "/ted-contacts",
      icon: faAddressBook,
      inside: [
        {
          type: "link",
          label: "All Contacts",
          route: "/ted-contacts",
          permission: "contacts",
          icon: faAddressCard,
        },
        {
          type: "link",
          label: "Teasing Contacts",
          route: "/ted-teasing-contacts",
          permission: "teasing-contacts",
          icon: faBullhorn,
        },
        {
          type: "dropdown",
          label: "Settings",
          icon: faUserGear,
          inside: [
            {
              type: "link",
              label: "Contact Types",
              permission: "contact-types",
              route: "/ted-contacts/contact-types",
              icon: faCircleInfo,
            },
            {
              type: "link",
              label: "Contact Fields",
              permission: "contact-fields",
              route: "/ted-contacts/contact-fields",
              icon: faCircleInfo,
            },
          ],
        },
      ],
    },
    {
      type: "link",
      label: "Projects",
      route: "/ted-projects",
      icon: faFolder,
    },
    {
      type: "link",
      label: "Support",
      route: "/ted-support-plans",
      icon: faTableList,
    },
    {
      type: "link",
      label: "Payments",
      // permission: "payments",
      route: "/ted-support-plans-payment",
      icon: faSackDollar,
    },
  ];

  const openDropdown = (event) => {
    var dropdown = event.target.closest(".dropdown");
    dropdown.classList.toggle("open");
  };

  const stayOpenDropdown = (event) => {
    event.stopPropagation();
    var dropdown = event.target.closest(".dropdown");
    dropdown.classList.add("open");
  };

  return (
    <div
      id="sidebar"
      className={`${sidebarRight ? "right" : ""} ${
        !sidebarOpen ? "closed" : ""
      }`}
    >
      <div
        onMouseLeave={() => {
          if (!sidebarOpen && window.innerWidth > 1200) {
            toggleSidebarExpanded();
          }
        }}
        className={`h-100 ${sidebarExpanded ? "expanded" : ""}`}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {sidebarExpanded && !sidebarOpen && (
            <button
              className="btn btn-lighter-blue icon-btn toggle-sidebar-expanded"
              style={{position: "absolute", left: 15, top: 15,}}
              onClick={() => {
                toggleSidebar();
              }}
            >
              <FontAwesomeIcon
                icon={sidebarRight ? faAnglesLeft : faAnglesRight}
                color="#000"
              />
            </button>
          )}
          <button
            className="btn btn-primary icon-btn close-sidebar"
            onClick={() => {
              closeSidebar();
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
            <img src={logo} alt="TedMob Logo" style={{ width: 50 }} />
        </div>
        <div>
          <ul>
            {sidebar_content.map((child, index) => {
              if (
                child.type == "link" &&
                (!child.permission ||
                  authUser?.permissions.includes(child.permission))
              ) {
                return (
                  <li key={index} class="sidebar-li">
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      style={{ marginBottom: 10 }}
                      to={child.route}
                    >
                      {child.icon && <FontAwesomeIcon icon={child.icon} />}

                      {child.label}
                    </NavLink>
                  </li>
                );
              } else if (child.type == "section") {
                return (
                  <div
                    className="section dropdown navTitles"
                    key={index}
                    onClick={(event) => {
                      openDropdown(event);
                    }}
                  >
                    <Link
                      className="section-toggle d-flex clickable align-items-center no-decoration"
                      to={child.link}
                    >
                      {child.icon && (
                        <FontAwesomeIcon
                          icon={child.icon}
                          className="iconColor"
                          style={{ width: 25, marginRight: 10 }}
                        />
                      )}
                      <h4
                        style={{
                          color: "#06003b",
                          fontWeight: "600",
                          fontSize: 15,
                        }}
                      >
                        {child.title}
                      </h4>

                      <h5 className="ml-auto">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </h5>
                    </Link>
                    <div>
                      <div style={{ margin: "0px 0px 00px 0px" }}>
                        {child.inside.map((inside, index1) => {
                          if (
                            inside.type == "link" &&
                            (!inside.permission ||
                              authUser?.permissions.includes(inside.permission))
                          ) {
                            return (
                              <li key={index1} style={{ marginBottom: 5 }}>
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive ? "active" : ""
                                  }
                                  style={{
                                    padding: "0px 20px",
                                    height: 50,
                                    color: "#616161",
                                    borderRadius: 10,
                                  }}
                                  to={inside.route}
                                  onClick={(event) => {
                                    stayOpenDropdown(event);
                                  }}
                                >
                                  {inside.icon && (
                                    <FontAwesomeIcon
                                      icon={inside.icon}
                                      style={{
                                        display: "flex",
                                        width: 20,
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    />
                                  )}

                                  {inside.label}
                                </NavLink>
                              </li>
                            );
                          } else if (inside.type == "dropdown") {
                            return (
                              <div className="dropdown" key={index1}>
                                <div
                                  className="clickable dropdown-toggle d-flex align-items-center"
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 15,
                                    padding: "0px 20px",
                                    height: 50,
                                    borderRadius: 10,
                                    color: "#616161",
                                  }}
                                >
                                  {inside.icon && (
                                    <FontAwesomeIcon icon={inside.icon} />
                                  )}
                                  {inside.label}
                                  <h6
                                    className="ml-auto"
                                    onClick={(event) => {
                                      openDropdown(event);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronRight}
                                      style={{ fontSize: 14 }}
                                    />
                                  </h6>
                                </div>
                                <div>
                                  <div
                                    data-childcount={inside.inside.length}
                                    style={{
                                      backgroundColor: "transparent",
                                      marginBottom: 5,
                                    }}
                                  >
                                    {inside.inside.map((inside1, index2) => {
                                      if (
                                        inside1.type == "link" &&
                                        (!inside1.permission ||
                                          authUser?.permissions.includes(
                                            inside1.permission
                                          ))
                                      ) {
                                        return (
                                          <li
                                            key={index2}
                                            style={{
                                              marginBottom: 5,
                                            }}
                                          >
                                            <NavLink
                                              className={({ isActive }) =>
                                                isActive ? "active" : ""
                                              }
                                              style={{
                                                padding: "0px 20px",
                                                height: 50,
                                                borderRadius: 10,
                                                color: "#616161",
                                              }}
                                              to={inside1.route}
                                              // onClick={(event) => {
                                              //   stayOpenDropdown(event);
                                              // }}
                                            >
                                              {inside1.icon && (
                                                <FontAwesomeIcon
                                                  icon={inside1.icon}
                                                />
                                              )}

                                              {inside1.label}
                                            </NavLink>
                                          </li>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <div
                          style={{
                            display: "block",
                            margin: "0px auto",
                            width: "80%",
                            height: 1,
                            backgroundColor: "#06003b20",
                            marginBottom: 7,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

{
  /* <NavLink
          className={({ isActive }) => (isActive ? "link active" : "link")}
          to="/settings"
        >
          Settings
        </NavLink> */
}
