import { configureStore } from "@reduxjs/toolkit";

// Import the slice reducers
import contactTypesReducer from "./features/contacts/ContactTypesSlice";
import contactFieldsReducer from "./features/contacts/ContactFieldsSlice";
import contactReducer from "./features/contacts/ContactsSlice";
import rolesReducer from "./features/roles/RolesSlice";
import permissionsReducer from "./features/permissions/PermissionsSlice";
import usersReducer from "./features/users/UsersSlice";
import projectsReducer from "./features/projects/ProjectsSlice";
import authenticationReducer from "./features/Authentication/AthenticationSlice";
import teasingContactsReducer from "./features/contacts/TeasingContactsSlice";
import dashboardReducer from "./features/dashboard/DashboardSlice";
import supportPlansReducer from "./features/support-plans/SupportPlansSlice";
import supportPlansPaymentReducer from "./features/support-plans/SupportPlanPaymentSlice";
import invoiceReducer from "./features/support-plans/components/Invoice/InvoiceSlice";
import noticeReducer from "./features/support-plans/components/Notice/NoticeSlice";

export const store = configureStore({
  // Add the slices to the store so they can be accessed by any components on the app
  reducer: {
    dashboard: dashboardReducer,
    authentication: authenticationReducer,
    contactTypes: contactTypesReducer,
    contactFields: contactFieldsReducer,
    contacts: contactReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    users: usersReducer,
    projects: projectsReducer,
    teasingContacts: teasingContactsReducer,
    supportPlans: supportPlansReducer,
    supportPlansPayment: supportPlansPaymentReducer,
    invoice: invoiceReducer,
    notice: noticeReducer,
  },
});
