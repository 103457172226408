import React, { useState, useEffect } from "react";
import Modal from "../../../../layouts/modal/Modal";
import { useDispatch } from "react-redux";
import "../../SupportPlansPayment.css";
import { submitInvoice } from "./InvoiceSlice";
import { ToastContainer } from "react-toastify";
import { showToast } from "../Toast";

const SendInvoiceModal = ({
  sendInvoice,
  setSendInvoice,
  paymentPlanForm,
  // formErrors,
}) => {
  const nowDate = new Date().toLocaleDateString();
  const dispatch = useDispatch();

  // Initialize state with the paymentPlanForm
  const [invoiceData, setInvoiceData] = useState({
    id: "",
    client: "",
    client_email: "",
    project: "",
    amount: "",
    amount_paid: "",
    payment_due_date: "",
    payment_date: "",
    invoice_objective: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (paymentPlanForm) {
      setInvoiceData({
        id: paymentPlanForm.id,
        client: paymentPlanForm.client || "",
        client_email: paymentPlanForm.client_email || "",
        project: paymentPlanForm.project || "",
        amount: paymentPlanForm.amount || "",
        amount_paid: paymentPlanForm.amount_paid || "",
        payment_due_date: paymentPlanForm.payment_due_date || "",
        payment_date: paymentPlanForm.payment_date || "",
        invoice_objective: paymentPlanForm.invoice_objective || "",
      });
    }
  }, [paymentPlanForm]);

  // Update form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!invoiceData.invoice_objective) {
      newErrors.invoice_objective = "Objective is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitInvoice = () => {
    if (validate()) {
      dispatch(submitInvoice(invoiceData));
      showToast(submissionMessage);
    }
  };

  const submissionMessage = "Invoice email sent successfully";

  return (
    sendInvoice && (
      <Modal
        classes="bg-secondary"
        onCloseModalClick={() => setSendInvoice(false)}
      >
        <div className="invoice-modal">
          <ToastContainer />
          <div style={{marginRight: 20,}}>
            <h1 className="text-primary" style={{ paddingBottom: 7 }}>
              Send Invoice
            </h1>
            <div className="form-group" style={{ paddingBottom: 7 }}>
              <label>Client</label>
              <input
                name="client"
                value={invoiceData.client}
                onChange={handleInputChange}
                placeholder="Client"
              />
            </div>
            <div className="form-group" style={{ paddingBottom: 7 }}>
              <label>Email</label>
              <input
                name="client_email"
                value={invoiceData.client_email}
                onChange={handleInputChange}
                placeholder="Client Email"
              />
            </div>

            <div className="form-group" style={{ paddingBottom: 7 }}>
              <label>Project</label>
              <input
                name="project"
                value={invoiceData.project}
                onChange={handleInputChange}
                placeholder="Project"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 7 }}>
              <div
                className="form-group"
                style={{ minWidth: 100, paddingBottom: 7 }}
              >
                <label>Amount</label>
                <input
                  name="amount"
                  type="number"
                  value={invoiceData.amount}
                  onChange={handleInputChange}
                  placeholder="Amount"
                />
              </div>
              <div
                className="form-group"
                style={{ minWidth: 100, paddingBottom: 7 }}
              >
                <label>Due Date</label>
                <input
                  name="payment_due_date"
                  type="date"
                  value={invoiceData.payment_due_date}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 7 }}>
              <div
                className="form-group"
                style={{ minWidth: 100, paddingBottom: 7 }}
              >
                <label>Amount Paid</label>
                <input
                  name="amount_paid"
                  value={invoiceData.amount_paid}
                  onChange={handleInputChange}
                  placeholder="Amount Paid"
                />
              </div>
              <div
                className="form-group"
                style={{ minWidth: 100, paddingBottom: 7 }}
              >
                <label>Payment Date</label>
                <input
                  name="payment_date"
                  type="date"
                  value={invoiceData.payment_date}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Objective</label>{" "}
              {errors.invoice_objective && (
                <p className="text-danger input-error">
                  {errors.invoice_objective}
                </p>
              )}
              <textarea
                name="invoice_objective"
                value={invoiceData.invoice_objective}
                onChange={handleInputChange}
                placeholder="Enter Invoice objective"
                style={{
                  height: 80,
                  outline: "none",
                  border: "none",
                  padding: 10,
                }}
              />
            </div>
            <button
              className="btn btn-lg btn-primary mx-auto"
              style={{ borderRadius: 50, transition: "0.3s all ease" }}
              type="submit"
              onClick={handleSubmitInvoice}
            >
              <h4>Send Invoice</h4>
            </button>
          </div>

          {/* PDF Preview */}
          <InvoicePDF
            invoiceData={invoiceData}
            nowDate={nowDate}
            handleInputChange={handleInputChange}
          />
        </div>
      </Modal>
    )
  );
};

// PDF component integrated here
const InvoicePDF = ({ invoiceData, nowDate, handleInputChange }) => (
  <div style={{ display: "flex", flexDirection: "column", marginTop: 20, }}>
    <div className="page">
      <div className="header">
        <p className="invoiceTitle">Invoice</p>
        <p className="invoiceDate">Date: {nowDate}</p>
      </div>

      {/* Client Information */}
      <div className="clientInfo">
        <p className="sectionTitleInvert">Client Info</p>
        <div className="clientDetails">
          <div className="clientInnerDetails">
            <p className="label">Client:</p>
            <p className="text">{invoiceData.client}</p>
          </div>
          <div className="clientInnerDetails">
            <p className="label">Email:</p>
            <p className="text">{invoiceData.client_email}</p>
          </div>
          <div className="clientInnerDetails">
            <p className="label">Project:</p>
            <p className="text">{invoiceData.project}</p>
          </div>
        </div>
      </div>

      {/* Invoice Details */}
      <div className="invoiceDetails">
        <p className="sectionTitle">Invoice Details</p>
        <div className="detailsRow">
          <div className="detailsFirstRow">
            <p className="label">Amount:</p>
            <p className="text">${invoiceData.amount}</p>
          </div>
          <div className="detailsFirstRow">
            <p className="label">Amount Paid:</p>
            <p className="text">{invoiceData.amount_paid}</p>
          </div>
        </div>
        <div className="detailsRow">
          <div className="detailsFirstRow">
            <p className="label">Due Date:</p>
            <p className="text">{invoiceData.payment_due_date}</p>
          </div>
          <div className="detailsFirstRow">
            <p className="label">Paid Date:</p>
            <p className="text">{invoiceData.payment_date}</p>
          </div>
        </div>
      </div>

      {/* Message Section */}
      <div className="messageSection">
        <p className="sectionTitleInvert">Objective</p>
        <textarea
          style={{
            outline: "none",
            border: "none",
            boxShadow: "none",
            padding: 10,
          }}
          name="invoice_objective"
          value={invoiceData.invoice_objective}
          onChange={handleInputChange}
        />
      </div>

      {/* Footer */}
      {/* <div className="footer">
        <p className="footerp">TEDMOB</p>
      </div> */}
    </div>
  </div>
);

export default SendInvoiceModal;
