import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../layouts/modal/Modal";
import {
  faPen,
  faTrash,
  faMoneyCheck,
  faFileInvoice,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  fetchSupportPlansPayment,
  deleteSupportPlansPayment,
  updateSupportPlansPayment,
  selectSupportPlansPayment,
  // selectError,
} from "./SupportPlanPaymentSlice";
import "./SupportPlansPayment.css";
import SendInvoiceModal from "./components/Invoice/SendInvoiceModal";

const SupportPlansPayment = () => {
  const dispatch = useDispatch();
  const payments = useSelector(selectSupportPlansPayment);
  // const error = useSelector(selectError);
  const [searchFilter, setSearchFilter] = useState("");
  const [supportMenuOpen, setSupportMenuOpen] = useState(false);
  const [contextSupport, setContextSupport] = useState(null);
  const [supportMenuPosition, setSupportMenuPosition] = useState([0, 0]);
  const [activeTab, setActiveTab] = useState("transactions");
  const [editPaymentPlan, setEditPaymentPlan] = useState(null);
  const [sendInvoice, setSendInvoice] = useState(false);
  // const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    document.addEventListener("click", () => {
      setContextSupport(null);
      setSupportMenuOpen(false);
    });
    document.addEventListener("contextmenu", () => {
      setContextSupport(null);
      setSupportMenuOpen(false);
    });
  }, []);

  const [paymentPlanForm, setPaymentPlanForm] = useState({
    client: "",
    client_email: "",
    project: "",
    amount: "",
    amount_paid: "",
    payment_date: "",
  });

  // const validateForm = () => {
  //   let errors = {};

  //   if (!paymentPlanForm.amount_paid)
  //     errors.amount_paid = "Amount Paid is required.";
  //   if (!paymentPlanForm.payment_date)
  //     errors.payment_date = "Start date is required.";
  //   if (!paymentPlanForm.end_project_date) setFormErrors(errors);

  //   return Object.keys(errors).length === 0;
  // };

  useEffect(() => {
    dispatch(fetchSupportPlansPayment());
  }, [dispatch]);

  const handleEditPlanClick = (plan) => {
    if (!plan) {
      console.error("Plan is undefined");
      return;
    }

    const { client, client_email, project, amount } = plan.support_plan;
    const { amount_paid, created_at } = plan;

    setEditPaymentPlan(plan._id);
    setPaymentPlanForm({
      client,
      client_email,
      project,
      amount,
      amount_paid,
      payment_date: formFormatDate(created_at),
    });
    setSupportMenuOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setPaymentPlanForm((prev) => {
      const updatedForm = {
        ...prev,
        [name]: value,
      };

      if (name === "amount_paid") {
        const amount = parseFloat(paymentPlanForm.amount) || 0;
        const amountPaid = parseFloat(value) || 0;
        if (amountPaid > amount) {
          updatedForm.amount_paid = amount.toString();
        }
        updatedForm.remainingAmount = Math.max(0, amount - amountPaid);
      }

      return updatedForm;
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateSupportPlansPayment({
        id: editPaymentPlan,
        updatedPayment: paymentPlanForm,
      })
    );
    setEditPaymentPlan(null);
  };

  const handleSendInvoice = (payment) => {
    setPaymentPlanForm({
      id: payment._id,
      client: payment.support_plan.client,
      client_email: payment.support_plan.client_email,
      project: payment.support_plan.project,
      amount: payment.support_plan.amount,
      amount_paid: `$${payment.amount_paid.toLocaleString()}`,
      payment_due_date: formFormatDate(payment.support_plan.payment_due_date),
      payment_date: formFormatDate(payment.created_at),
    });
    setSendInvoice(true);
  };

  const filteredPayments = payments.filter((payment) => {
    const { client, project } = payment.support_plan || {};
    return (
      (client && client.toLowerCase().includes(searchFilter.toLowerCase())) ||
      (project && project.toLowerCase().includes(searchFilter.toLowerCase()))
    );
  });

  const handleOpenContextMenu = (event, params) => {
    event.preventDefault();
    event.stopPropagation();

    const x =
      event.clientX + 240 > window.innerWidth
        ? event.clientX - 230
        : event.clientX;
    const y =
      event.clientY + 200 > window.innerHeight
        ? event.clientY - 120
        : event.clientY;

    setSupportMenuPosition([x, y]);
    setContextSupport(params.id);
    setTimeout(() => {
      setSupportMenuOpen(true);
    }, 100);
  };

  const columns = [
    { field: "client", headerName: "Client Name", flex: 1 },
    { field: "client_email", headerName: "Client Email", flex: 1.5 },
    ...(activeTab === "transactions"
      ? [
          { field: "project", headerName: "Project", flex: 1 },
          { field: "amount", headerName: "Amount", flex: 1 },
        ]
      : []),
    { field: "amountPaid", headerName: "Amount Paid", flex: 1 },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <IconButton onClick={(e) => handleOpenContextMenu(e, params)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
  ];

  const rows = filteredPayments.map((payment) => ({
    id: payment._id,
    client: payment.support_plan.client,
    client_email: payment.support_plan.client_email,
    project: activeTab === "transactions" ? payment.support_plan.project : "",
    amount:
      activeTab === "transactions"
        ? `$${payment.support_plan.amount.toLocaleString()}`
        : "",
    amountPaid: `$${payment.amount_paid.toLocaleString()}`,
    paymentDate: payment.created_at,
  }));

  const formFormatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  return (
    <div id="support-plan-payments">
      <div
        className="d-flex gap-2 align-items-center m-6"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="text-primary text-xl" style={{ paddingTop: 13 }}>
          Ted Plans Payments
        </h3>
        <div className="tabs">
          <button
            className={`tab ${activeTab === "invoices" ? "active" : ""}`}
            onClick={() => setActiveTab("invoices")}
          >
            <FontAwesomeIcon icon={faFileInvoice} style={{ paddingRight: 6 }} />
            Invoices
          </button>
          <button
            className={`tab ${activeTab === "transactions" ? "active" : ""}`}
            onClick={() => setActiveTab("transactions")}
          >
            <FontAwesomeIcon icon={faMoneyCheck} style={{ paddingRight: 6 }} />
            Transactions
          </button>
        </div>
      </div>

      {/* Search Bar */}
      <div className="d-flex gap-2 w-100 align-items-center m-6">
        <input
          type="text"
          placeholder="Search"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          className="bg-primary search"
        />
        <button
          className="btn btn-lg btn-primary"
          onClick={() => setSearchFilter("")}
          style={{ borderRadius: 20, padding: "10px 20px" }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{ paddingRight: "5px", fontSize: 12 }}
          />
          Clear
        </button>
      </div>

      {/* Data Grid Transactions*/}
      {activeTab === "transactions" && (
        <div className="table-container">
          <div className="plans-table">
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooter
              rowHeight={80}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "" : "even-row"
              }
              sx={{
                minHeight: 100,
                borderRadius: 2,
                border: "none",
                "& .even-row": { backgroundColor: "#f0f4ff" },
              }}
              components={{
                NoRowsOverlay: () => (
                  <div
                    style={{
                      display: "flex",
                      height: 300,
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 600,
                    }}
                  >
                    No support plan payments available.
                  </div>
                ),
              }}
            />
          </div>
        </div>
      )}

      {/* Data Grid Transactions*/}
      {activeTab === "invoices" && (
        <div className="table-container">
          <div className="plans-table">
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooter
              rowHeight={80}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "" : "even-row"
              }
              sx={{
                minHeight: 100,
                borderRadius: 2,
                border: "none",
                "& .even-row": { backgroundColor: "#f0f4ff" },
              }}
              components={{
                NoRowsOverlay: () => (
                  <div
                    style={{
                      display: "flex",
                      height: 300,
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 600,
                    }}
                  >
                    No support plan payments available.
                  </div>
                ),
              }}
            />
          </div>
        </div>
      )}

      {/* Context Support Menu*/}
      {supportMenuOpen && (
        <div
          id="context-menu"
          style={{
            left: `${supportMenuPosition[0]}px`,
            top: `${supportMenuPosition[1]}px`,
          }}
          className="context-menu"
        >
          {activeTab === "transactions" && (
            <>
              <div
                onClick={() =>
                  handleEditPlanClick(
                    payments.find((p) => p._id === contextSupport)
                  )
                }
                className="transition-smooth"
              >
                <FontAwesomeIcon icon={faPen} />
                Edit
              </div>
              <div
                onClick={() =>
                  handleSendInvoice(
                    payments.find((p) => p._id === contextSupport)
                  )
                }
                className="transition-smooth"
              >
                <FontAwesomeIcon icon={faFileInvoice} />
                Send Payment Invoice
              </div>
            </>
          )}
          <div
            onClick={() => {
              dispatch(deleteSupportPlansPayment(contextSupport));
              setSupportMenuOpen(false);
            }}
            className="transition-smooth"
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </div>
        </div>
      )}

      {/* Edit Payment Plan Modal */}
      {editPaymentPlan && (
        <Modal
          classes="bg-secondary"
          onCloseModalClick={() => setEditPaymentPlan(false)}
        >
          <div className="support-plan-modal">
            <h1 className="text-primary" style={{ marginBottom: 20 }}>
              Edit Payment
            </h1>
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex" style={{ gap: 10 }}>
                <div className="form-group">
                  <label>Client Info</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Client"
                    name="client"
                    value={paymentPlanForm.client}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label>Client Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Client Email"
                    name="client_email"
                    value={paymentPlanForm.client_email}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex" style={{ gap: 10 }}>
                <div className="form-group">
                  <label>Project</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Project"
                    name="project"
                    value={paymentPlanForm.project}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label>Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    name="amount"
                    value={paymentPlanForm.amount}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex" style={{ gap: 10 }}>
                <div className="form-group">
                  <label>Amount Paid</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount Paid"
                    name="amount_paid"
                    value={paymentPlanForm.amount_paid}
                    onChange={handleFormChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Payment Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="payment_date"
                    value={paymentPlanForm.payment_date}
                    onChange={handleFormChange}
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-success mx-auto"
                style={{ borderRadius: 50, transition: "0.3s all ease" }}
              >
                Update
              </button>
            </form>
          </div>
        </Modal>
      )}

      <SendInvoiceModal
        sendInvoice={sendInvoice}
        setSendInvoice={setSendInvoice}
        paymentPlanForm={paymentPlanForm}
        // formErrors={formErrors}
      />
    </div>
  );
};

export default SupportPlansPayment;
