import "./Contacts.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CSVLink } from "react-csv";

import {
  reset,
  fetchTeasingContacts,
  deleteTeasingContact,
} from "./TeasingContactsSlice";

import { SelectStyles } from "../../layouts/select/SelectStyles";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faFileExcel,
  faFileExport,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";

function TeasingContacts() {
  const dispatch = useDispatch();
  const { teasingContacts, isLoading, isSuccess, isError, message } =
    useSelector((store) => store.teasingContacts);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [Filters, setFilters] = useState({
    search: "",
    from_date: `${currentYear}-${
      currentMonth + 1 < 10 ? `0${currentMonth + 1}` : currentMonth + 1 < 10
    }-01`,
    to_date: `${currentMonth == 11 ? currentYear + 1 : currentYear}-${
      currentMonth == 11
        ? "01"
        : currentMonth + 2 < 10
        ? `0${currentMonth + 2}`
        : currentMonth + 2
    }-01`,
  });

  const FilteredTeasingContacts = teasingContacts
    ? teasingContacts.filter((teasingContact) => {
        let show = true;
        if (Filters["search"]) {
          show = false;
          if (teasingContact.name.toLowerCase().includes(Filters["search"])) {
            show = true;
          }
          if (teasingContact.email.toLowerCase().includes(Filters["search"])) {
            show = true;
          }
          if (
            new Date(teasingContact.created_at)
              .toLocaleString()
              .includes(Filters["search"])
          ) {
            show = true;
          }
        }
        if (Filters.from_date) {
          if (
            new Date(teasingContact.created_at).setHours(0, 0, 0, 0) <
            new Date(Filters.from_date).setHours(0, 0, 0, 0)
          ) {
            show = false;
          }
        }
        console.log(Filters.to_date);
        if (Filters.to_date) {
          if (
            new Date(teasingContact.created_at).setHours(0, 0, 0, 0) >
            new Date(Filters.to_date).setHours(0, 0, 0, 0)
          ) {
            show = false;
          }
        }
        return show;
      })
    : [];

  const [TeasingContactToDelete, setTeasingContactToDelete] = useState();

  useEffect(() => {
    if (!teasingContacts) {
      dispatch(fetchTeasingContacts());
    }
    return () => {};
  }, [teasingContacts]);

  useEffect(() => {
    if (isSuccess || isError) {
      setTeasingContactToDelete(null);
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <>
      <div id="teasingContacts">
        <div className="betterFlex">
          <Link className="no-decoration" to="/ted-teasingContacts">
            <h3 className="text-primary text-xl" style={{ paddingTop: 13 }}>
              Ted Teasing Contacts
            </h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <CSVLink
            className="ml-auto btn btn-primary no-decoration"
            data={FilteredTeasingContacts}
            style={{
              borderRadius: "50px",
              backgroundColor: "#562AD1",
              padding: "10px 20px",
            }}
          >
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ fontSize: 15, paddingRight: "5px" }}
            />
            <h5>Export Data</h5>
          </CSVLink>
          <CSVLink
            className="btn btn-primary no-decoration"
            data={FilteredTeasingContacts}
            style={{
              borderRadius: "50px",
              backgroundColor: "#562AD1",
              padding: "10px 20px",
            }}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              style={{ fontSize: 16, paddingRight: "5px" }}
            />
            <h5>Export All Data</h5>
          </CSVLink>
          <button
            onClick={() => {
              dispatch(reset());
              dispatch(
                deleteTeasingContact({
                  ids: FilteredTeasingContacts.map((c) => c._id),
                })
              );
            }}
            className="btn btn-danger"
            style={{ borderRadius: "50px", padding: "10px 20px" }}
          >
            <FontAwesomeIcon style={{ paddingRight: "5px" }} icon={faTrash} />
            Bulk Delete
          </button>
        </div>

        {/* Filters */}
        <div
          className="bg-primary card table-container2"
          style={{ borderRadius: 10 }}
        >
          <div className="d-flex gap-2 w-100 align-items-center">
            <div className="form-group w-100" style={{ padding: "0" }}>
              <label>From Date</label>
              <input
                type="date"
                data-type="text"
                className="new-input"
                placeholder="From Date"
                value={Filters["from_date"]}
                onChange={(event) => {
                  setFilters((prev) => {
                    return { ...prev, from_date: event.target.value };
                  });
                }}
              />
            </div>
            <div className="w-100 form-group" style={{ padding: "0" }}>
              <label>To Date</label>
              <input
                className="new-input"
                style={{ cursor: "pointer" }}
                type="date"
                data-type="text"
                placeholder="To Date"
                value={Filters["to_date"]}
                onChange={(event) => {
                  setFilters((prev) => {
                    return { ...prev, to_date: event.target.value };
                  });
                }}
              />
            </div>
            <div className="w-100 form-group" style={{ padding: "0" }}>
              <label>Search</label>
              <input
                data-type="text"
                className="new-input"
                placeholder="Search"
                value={Filters["search"]}
                onChange={(event) => {
                  setFilters((prev) => {
                    return { ...prev, search: event.target.value };
                  });
                }}
              />
            </div>
            <button
              className="btn btn-primary"
              style={{
                borderRadius: "50px",
                padding: "10px 20px",
                marginTop: "30px",
              }}
              onClick={() => {
                setFilters((prev) => {
                  return {
                    search: "",
                    from_date: "",
                    to_date: "",
                  };
                });
              }}
            >
              <h4 style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faXmark} />
              </h4>
              Clear Filters
            </button>
          </div>
        </div>

        <div className="table-container">
          <div className="table-header">
            <div className="table-cell small-width" style={{ width: "30%" }}>
              Name
            </div>
            <div className="table-cell" style={{ width: "30%" }}>
              Email
            </div>
            <div className="table-cell" style={{ width: "30%" }}>
              Created at
            </div>
            <div className="table-cell" style={{ width: "10%" }}>
              Actions
            </div>
          </div>

          {isLoading && (
            <span
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              Loading...
            </span>
          )}
          {!isLoading &&
            FilteredTeasingContacts?.length > 0 &&
            FilteredTeasingContacts.map((teasingContact) => (
              <div className="table-row" key={teasingContact._id}>
                <div
                  className="table-cell small-width fw-semibold text-primary"
                  style={{ width: "30%" }}
                >
                  {teasingContact.name}
                </div>
                <div
                  className="table-cell fw-semibold text-primary"
                  style={{ width: "30%" }}
                >
                  {teasingContact.email}
                </div>
                <div
                  className="table-cell fw-semibold text-primary"
                  style={{ width: "30%" }}
                >
                  {new Date(teasingContact.created_at).toLocaleString()}
                </div>
                <div className="table-cell" style={{ width: "10%" }}>
                  <button
                    title="Delete TeasingContact"
                    onClick={() => {
                      dispatch(reset());
                      setTeasingContactToDelete(teasingContact._id);
                    }}
                    className="icon-btn btn btn-danger"
                    style={{ borderRadius: "50%" }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            ))}

          {!isLoading && teasingContacts?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Delete TeasingContact Modal */}
      {TeasingContactToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setTeasingContactToDelete(null);
          }}
        >
          <div className="delete-teasingContact-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this Teasing contact?
            </h3>
            <button
              onClick={() => {
                dispatch(
                  deleteTeasingContact({
                    ids: [TeasingContactToDelete],
                  })
                );
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default TeasingContacts;
