import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faFileInvoice,
  faDollarSign,
  faCircle,
  faTrash,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { showToast } from "./Toast";

const SupportContextMenu = ({
  SupportMenuOpen,
  SupportMenuPosition,
  contextSupport,
  filteredPlans,
  state,
  submissionMessage,
  hiddenSubmissionMessage,
  amountPaid,
  handlePaymentAmountChange,
  handlePaymentAmountSubmit,
  isInputDisabled,
  handleEditPlanClick,
  handleSendNotice,
  dispatch,
  updateSupportPlan,
  handleDeletePlan,
  setSupportMenuOpen,
  placeHolder,
}) => {
  useEffect(() => {
    if (submissionMessage) {
      showToast(submissionMessage);
    }
  }, [submissionMessage]);
  return (
    <>
      {SupportMenuOpen && (
        <div
          id="context-menu"
          style={{
            left: `${SupportMenuPosition[0]}px`,
            top: `${SupportMenuPosition[1]}px`,
          }}
          className="context-menu"
        >
          <div
            onClick={() => {
              handleEditPlanClick(
                filteredPlans.find((plan) => plan._id === contextSupport)
              );
            }}
            className="transition-smooth"
          >
            <FontAwesomeIcon icon={faPen} />
            Edit
          </div>

          <div
            onClick={(e) => e.stopPropagation()}
            className="transition-smooth"
          >
            {isInputDisabled ? (
              <div
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  textAlign: "center",
                  opacity: 0.8,
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ paddingRight: 9 }}
                />
                Fully Paid
              </div>
            ) : (
              <div
                  onClick={(e) => handlePaymentAmountSubmit(contextSupport, e)}
                  style={{
                    cursor: "pointer",
                    fontWeight: 600,
                    width: "100%",
                  }}
                >
                  <FontAwesomeIcon icon={faDollarSign} style={{paddingRight: 13}} />
                  Pay Now
                </div>
            )}
          </div>

          <div
            className="transition-smooth"
            onClick={() => {
              handleSendNotice(
                filteredPlans.find((plan) => plan._id === contextSupport)
              );
            }}
          >
            <FontAwesomeIcon icon={faFileInvoice} />
            Send Notice
          </div>

          {state !== "active" &&
            state !== "due" &&
            filteredPlans.find((plan) => plan._id === contextSupport)
              ?.status !== "active" && (
              <div
                onClick={() => {
                  dispatch(
                    updateSupportPlan({
                      id: contextSupport,
                      updatedPlan: { status: "active" },
                    })
                  );
                  setSupportMenuOpen(false);
                }}
                className="status-active"
                style={{ border: "none" }}
              >
                <FontAwesomeIcon icon={faCircle} style={{ fontSize: 10 }} />
                Set Active
              </div>
            )}
          {/* {state !== "new" &&
            filteredPlans.find((plan) => plan._id === contextSupport)
              ?.status !== "new" && (
              <div
                onClick={() => {
                  dispatch(
                    updateSupportPlan({
                      id: contextSupport,
                      updatedPlan: { status: "new" },
                    })
                  );
                  setSupportMenuOpen(false);
                }}
                className="status-new"
                style={{ border: "none" }}
              >
                <FontAwesomeIcon icon={faCircle} style={{ fontSize: 10 }} />
                Set New
              </div>
            )} */}
          {state !== "archive" &&
            filteredPlans.find((plan) => plan._id === contextSupport)
              ?.status !== "archive" && (
              <div
                onClick={() => {
                  dispatch(
                    updateSupportPlan({
                      id: contextSupport,
                      updatedPlan: { status: "archive" },
                    })
                  );
                  setSupportMenuOpen(false);
                }}
                className="status-archive"
                style={{ border: "none" }}
              >
                <FontAwesomeIcon icon={faCircle} style={{ fontSize: 10 }} />
                Set Archive
              </div>
            )}
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleDeletePlan(contextSupport);
              setSupportMenuOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default SupportContextMenu;
