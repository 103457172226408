import React, { useState, useEffect } from "react";
import Modal from "../../../../layouts/modal/Modal";
import { useDispatch } from "react-redux";
import { sendPaymentNotice } from "./NoticeSlice";
import { ToastContainer } from "react-toastify";
import { showToast } from "../Toast";

const SendNoticeModal = ({
  sendNotice,
  setSendNotice,
  planForm,
  formErrors,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [noticeData, setNoticeData] = useState({
    client: "",
    client_email: "",
    project: "",
    amount: "",
    payment_due_date: "",
    notice_subject: "",
    notice_message: "",
  });

  useEffect(() => {
    if (planForm) {
      setNoticeData({
        client: planForm.client || "",
        client_email: planForm.client_email || "",
        project: planForm.project || "",
        amount: planForm.amount || "",
        payment_due_date: planForm.payment_due_date || "",
        notice_subject: "",
        notice_message: "",
      });
    }
  }, [planForm]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNoticeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!noticeData.notice_subject) {
      newErrors.notice_subject = "Subject is required";
    }
    if (!noticeData.notice_message) {
      newErrors.notice_message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitInvoice = () => {
    if (validate()) {
      dispatch(sendPaymentNotice(noticeData));
      showToast("Notice sent successfully", "success");
    }
  };

  return (
    sendNotice && (
      <Modal
        classes="bg-secondary"
        onCloseModalClick={() => setSendNotice(false)}
      >
        <div className="support-plan-modal" style={{ width: 500 }}>
          <ToastContainer />
          <h1 className="text-primary" style={{ marginBottom: 20 }}>
            Send Notice
          </h1>
          <div className="startFlex">
            <div className="to">To</div>
            <span className="mailInfo">{planForm.client}</span>
            <span className="mailInfo">{planForm.client_email}</span>
          </div>
          <div className="form-group">
            <label>Subject</label>{" "}
            {errors.notice_subject && (
              <span className="text-danger input-error">
                {errors.notice_subject}
              </span>
            )}
            <input
              name="notice_subject"
              placeholder="Subject"
              style={{ marginBottom: 20 }}
              value={noticeData.notice_subject}
              onChange={handleInputChange}
            />
            <label>Notice Message</label>{" "}
            {errors.notice_message && (
              <span className="text-danger input-error">
                {errors.notice_message}
              </span>
            )}
            <textarea
              name="notice_message"
              value={noticeData.notice_message}
              style={{ height: 120 }}
              placeholder="Enter notice message"
              onChange={handleInputChange}
            />
            <p style={{ paddingTop: 20, fontWeight: 500, lineHeight: 1.5 }}>
              This is a friendly reminder that you have until{" "}
              <span style={{ color: "#1683FF", fontWeight: 600 }}>
                {noticeData.payment_due_date}
              </span>{" "}
              Thank you for your attention to this matter.
            </p>
            {/* {formErrors.client && (
              <span className="text-danger input-error">
                {formErrors.client}
              </span>
            )} */}
          </div>
          <div className="d-flex">
            <button
              className="btn btn-lg btn-success mx-auto"
              style={{ borderRadius: 50, transition: "0.3s all ease" }}
              type="submit"
              onClick={() => handleSubmitInvoice(noticeData)}
            >
              <h4>Send Notice</h4>
            </button>
          </div>
        </div>
      </Modal>
    )
  );
};

export default SendNoticeModal;
