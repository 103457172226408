export const SelectStyles = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    width: "100%",
  }),
  dropdownIndicator: (baseStyles, state) => ({
    display: "none",
  }),
  indicatorSeparator: (baseStyles, state) => ({
    display: "none",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "5px",
    border: "1px solid #bebebe9a",
    width: "100%",
    boxShadow: "none",
    outline: "none",
    backgroundColor: "#F9FAFB",
    marginTop: "5px",
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    boxShadow: "none !important",
    color: "var(--text-primary)",
    padding: "0",
    margin: "0",
    fontSize: "14px",
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--input-placeholder)",
    fontWeight: "500",
  }),

  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "var(--bg-primary)",
    boxShadow: "3px 3px 3px 0 var(--input-shadow)",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused
      ? "var(--select-option-hover-color)"
      : "var(--text-primary)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "0.5rem 1rem",
    backgroundColor: state.isFocused ? "var(--text-highlight)" : "#F9FAFB",
  }),

  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: "0.6rem 1rem",
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    margin: "0",
    marginRight: "3px",
    marginBottom: "2px",
    backgroundColor: "var(--btn-primary)",
    border: "none",
    borderRadius: "3px",
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--btn-color)",
    fontSize: "14px",
    fontWeight: "500",
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--btn-color) !important",
    background: state.isHover
      ? "var(--btn-danger) !important"
      : "var(--btn-primary)",
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--text-primary)",
    fontSize: "14px",
    fontWeight: "500",
  }),

  clearIndicator: (baseStyles, state) => ({
    ...baseStyles,
    display: "none",
  }),
  noOptionsMessage: (baseStyles, state) => ({
    ...baseStyles,
    color: "var(--text-secondary)",
    fontSize: "14px",
  }),
};
